import { makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';

import VehicleContext from '~/contexts/Vehicle';

import LeftInStock from '../LeftInStock';

const useStyles = makeStyles((theme: Theme) => ({
  leftInStockWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.4),
  },
}));

interface IProps {
  renderSubmitButton(): React.ReactNode;
}

const AvailabilityElements: React.FunctionComponent<IProps> = ({
  renderSubmitButton,
}) => {
  const classes = useStyles();
  const { vehicle } = useContext(VehicleContext);

  return (
    <>
      {renderSubmitButton()}

      <div className={classes.leftInStockWrapper}>
        <LeftInStock productStockTotal={vehicle.product_stock_total} />
      </div>
    </>
  );
};

export default AvailabilityElements;
