import { getAvailabilityText } from '@finn/ua-vehicle';
import { cn } from '@finn/ui-utils';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import VehicleContext from '~/contexts/Vehicle';
import { isVehicleComingSoon } from '~/utils/pdp';

const AvailabilityText: React.FC<{ showSmallerVersion?: boolean }> = ({
  showSmallerVersion,
}) => {
  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const isComingSoon = isVehicleComingSoon(vehicle);
  const availabilityText = useMemo(
    () => getAvailabilityText({ i18n, vehicle }),
    [i18n, vehicle]
  );

  return (
    <h6
      className={cn('global-t6-semibold', {
        'text-iron mb-2': isComingSoon,
        'text-green mb-2': !isComingSoon,
        'body-12-semibold': !!showSmallerVersion,
      })}
    >
      {availabilityText}
    </h6>
  );
};
export default AvailabilityText;
