import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@finn/atoms/Modal';
import React, { SyntheticEvent } from 'react';

import RawHtml from '~/components/RawHtml';

import { ContactUsBox } from '../ContactUsBox';

interface ModalData {
  modal_title: string;
  modal_text: string;
}

type Props = {
  open: boolean;
  onClose: (event?: SyntheticEvent) => void;
  data: ModalData;
};

export const PreLovedModal: React.FC<Props> = ({
  open,
  onClose,
  data: { modal_text, modal_title },
}) => {
  return (
    <Modal size="xl" open={open} onClose={onClose}>
      <ModalHeader>{modal_title}</ModalHeader>

      <ModalContent>
        <span className="body-16-light">
          <RawHtml html={modal_text} />
        </span>
      </ModalContent>
      <ModalFooter>
        <ContactUsBox />
      </ModalFooter>
    </Modal>
  );
};
