import { EmissionModal, EmissionModalContext } from '@finn/ua-emission';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { VehicleContext } from '@finn/ua-vehicle';
import { ButtonBase } from '@material-ui/core';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '../ProductDetails/utils/PDPTracking';

export const EmissionDetails = ({
  configureView,
}: {
  configureView?: boolean;
}) => {
  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const { modalOpen, setModalOpen } = useContext(EmissionModalContext);
  const drawerView = useIsABVariant(Features.ExpPDPCarInfo) && !configureView;

  const formattedCO2Emission = `${vehicle?.co2emission || 0} g/km`;
  const title = i18n.formatMessage({ id: 'pdp.keyData.emissions' });
  const efficiencyImage = vehicle?.energy_class_file?.url ?? '';

  const openModal = () => {
    PDPTracking.environmentalDetailsClicked();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div data-testid="emission-details">
      <p className={drawerView ? 'body-14-light' : 'body-16-light'}>{title}</p>
      {efficiencyImage ? (
        <ButtonBase
          role="button"
          data-testid="emissions-button"
          onClick={openModal}
        >
          <div className={drawerView ? 'link-14' : 'link-16 mt-5'}>
            {formattedCO2Emission}
          </div>
        </ButtonBase>
      ) : (
        <div className="global-t6-semibold mt-5">{formattedCO2Emission}</div>
      )}

      <EmissionModal open={modalOpen} onClose={closeModal} />
    </div>
  );
};
