import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { InfoToolTip } from '@finn/ui-components';
import React from 'react';

export interface IListItem {
  id: number;
  label: string;
  title: string;
  additionalInfo?: string;
  configureView?: boolean;
}

const KeyDataListItem: React.FunctionComponent<IListItem> = ({
  label,
  title,
  additionalInfo,
  configureView = false,
}) => {
  const drawerView = useIsABVariant(Features.ExpPDPCarInfo);

  return (
    <div className={configureView && 'flex justify-between'}>
      <div className="flex items-center">
        <p className={drawerView ? 'body-14-light' : 'body-16-light'}>
          {title}
        </p>
        {additionalInfo && (
          <div className="ml-2">
            <InfoToolTip content={additionalInfo} isSmallVariant={true} />
          </div>
        )}
      </div>
      <div
        className={
          drawerView ? 'body-14-semibold mt-2' : 'global-t6-semibold mt-5'
        }
      >
        {label?.length ? label : '-'}
      </div>
    </div>
  );
};

export default KeyDataListItem;
