import { Container } from '@finn/atoms';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { slugify, useCurrentLocale } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  interlinkSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingRight: theme.spacing(20),
  },
  link: {
    display: 'flex',
  },
}));

type Props = {
  vehicle: GenericVehicleDetails;
};

export const Interlink: React.FunctionComponent<Props> = ({ vehicle }) => {
  const classes = useStyles();
  const i18n = useIntl();
  const { locale } = useCurrentLocale();

  const trackInterlinkClicked = useCallback((name: string, link: string) => {
    interactionTrackingEvent(TrackingEventName.INTERLINK_CLICKED, {
      location: 'pdp',
      name,
      link,
    });
  }, []);

  const buildInterlinks = useMemo(() => {
    const { cartype, fuel, gearshift } = vehicle;
    const carData = { cartype, fuel, gearshift };

    return Object.entries(carData).map(([key, value]) => {
      const link = `/${BASE_SUBSCRIPTION_PATH}/${slugify(value)}`;

      return (
        <div className={classes.interlinkSection} key={key}>
          <h3 className="global-t5-semibold">
            {i18n.formatMessage({ id: `pdp.keyData.${key}` })}
          </h3>
          <div className={classes.link}>
            <a
              className="link-16"
              target="_blank"
              href={`${locale}${link}`}
              onClick={() => trackInterlinkClicked(key, link)}
              rel="noreferrer"
            >
              {value}
            </a>
          </div>
        </div>
      );
    });
  }, []);

  return (
    <Container>
      <h2 className="mobile-t3-semibold md:web-t3-semibold mb-8 sm:mb-10">
        {i18n.formatMessage({
          id: 'pdp.interlinking.title',
        })}
      </h2>
      <div className={classes.content}>{buildInterlinks}</div>
    </Container>
  );
};
