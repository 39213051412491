import { Container } from '@finn/atoms';
import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';

import ProductLabel from '~/components/ProductLabel';
import VehicleContext from '~/contexts/Vehicle';
import { isVehicleComingSoon } from '~/utils/pdp';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const ProductLabelsBar: React.FunctionComponent = () => {
  const classes = useStyles();
  const { vehicle } = useContext(VehicleContext);
  const isComingSoon = isVehicleComingSoon(vehicle);

  return (
    <Container className={classes.container}>
      {!isComingSoon && <ProductLabel vehicle={vehicle} />}
    </Container>
  );
};

export default ProductLabelsBar;
