import { Modal, ModalContent } from '@finn/atoms/Modal';
import { VehicleContext } from '@finn/ua-vehicle';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
  },
}));

type VehiclesBenefitsModalProps = {
  isOpen: boolean;
  index: number | null;
  onClose(): void;
};

const VehiclesBenefitsModal = ({
  isOpen,
  index,
  onClose,
}: VehiclesBenefitsModalProps) => {
  const classes = useStyles();

  const { fearAwayItems } = useContext(VehicleContext);

  const { title, description } = fearAwayItems[index] || {};

  return (
    <Modal
      size="xs"
      open={isOpen}
      onClose={onClose}
      scrollAll
      disableBrowserHistory
    >
      <ModalContent>
        <div className={classes.root}>
          <h6 className="mobile-t4-semibold sm:web-t4-semibold">{title}</h6>

          <p className="body-16-light mb-8 mt-6 whitespace-pre-wrap">
            {description}
          </p>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default VehiclesBenefitsModal;
