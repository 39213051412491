import { PDPModules } from '@finn/auto-ui/types/cosmicModules';
import { ProductSliderCosmicData } from '@finn/auto-ui/ui-modules/ProductSlider/types';
import { SpacingWrapper } from '@finn/platform-modules';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { GenericVehicleDetails, Review } from '@finn/ua-vehicle';

import { PageDataProvider } from '~/contexts/PageData';
import { CosmicModuleType, ModuleType } from '~/types/UIModules';
import CosmicModule from '~/ui-modules/CosmicModule';
import { updatePDProductSlider } from '~/utils/pdp';

export type CosmicModulesProps = {
  reviews: Review[];
  pdpModules: PDPModules;
  vehicle: GenericVehicleDetails;
};

export const CoscmicModules = ({
  reviews,
  pdpModules,
  vehicle,
}: CosmicModulesProps) => {
  const isExpLocationPDPBVariant = useIsABVariant(Features.ExpLocationPDP);
  const isExpLocationPDPCVariant = useIsCVariant(Features.ExpLocationPDP);
  let pageModules = [...pdpModules.metadata.modules];

  /*
     This is for the Experiment Location PDP B and C variants
     if B or C variant is enabled, we don't render following modules:
    - product slider
    - Compare table
    @TODO: Remove this condition once the experiment is over
  */
  if (isExpLocationPDPBVariant) {
    pageModules = pageModules.filter(
      (module: ModuleType) =>
        module.type !== CosmicModuleType.WEB_MODULE_COMPARISON
    );
  }

  if (isExpLocationPDPCVariant) {
    pageModules = pageModules.filter(
      (module: ModuleType) => module.slug !== 'carslider-standardsorting'
    );
  }

  const renderModule = (module: ModuleType) => {
    let updatedModule = module;

    if (
      module.type === CosmicModuleType.PRODUCT_SLIDER &&
      (module as ProductSliderCosmicData).metadata.dynamic_content
    ) {
      updatedModule = updatePDProductSlider(
        module as ProductSliderCosmicData,
        vehicle
      );
    }

    return (
      <SpacingWrapper key={module.slug}>
        <CosmicModule data={updatedModule} />
      </SpacingWrapper>
    );
  };

  return (
    <PageDataProvider reviews={reviews}>
      {pageModules.map((module: ModuleType) => renderModule(module))}
    </PageDataProvider>
  );
};

export default CoscmicModules;
