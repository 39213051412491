import { Features, useIsABVariant } from '@finn/ua-featureflags';
import React from 'react';

type Item = {
  id: number;
  title: string;
  key: string;
  alt?: string;
};

type Props = {
  title?: string;
  items: Item[];
};

const EquipmentCollapseContent = ({ title, items }: Props) => {
  const isExpPDPCarInfo = useIsABVariant(Features.ExpPDPCarInfo);

  return (
    <>
      {title && (
        <p
          className={
            isExpPDPCarInfo ? 'body-14-semibold py-2' : 'body-16-semibold py-2'
          }
        >
          {title}
        </p>
      )}
      <ul className="m-0 px-5">
        {items.map((item) => (
          <li
            key={item.id}
            title={item.alt}
            className={
              isExpPDPCarInfo
                ? 'body-14-light before:hidden after:hidden'
                : 'body-16-light before:hidden after:hidden'
            }
          >
            {item.title}
          </li>
        ))}
      </ul>
    </>
  );
};

export default EquipmentCollapseContent;
