import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderVariant,
} from '@finn/atoms';
import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage/ConfigurePageStore';
import { ContinueButton } from '@finn/auto-ui/components/ConfigurePage/ContinueButton';
import { DownPayment } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownPayment';
import { DownPaymentExperiment } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownPaymentExperiment';
import { useViewportHeight } from '@finn/auto-ui/hooks/useViewportHeight';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { VehicleContext } from '@finn/ua-vehicle';
import { getForBusinessCookie, usePreDiscountRawValue } from '@finn/ui-modules';
import { isMobileApp, useFormattedPrice, useIsMobile } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContractDuration, DistanceOptions } from '~/components/ConfigurePage';

import { useMonthlySubscriptionCost } from '../PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { ConfigureVehicleHeader } from './ConfigureVehicleHeader';
import { DownpaymentOptionsModal } from './DownpaymentModal';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 10, 3),
    },
  },
  imageWrapper: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    width: 146,
  },
  image: {
    maxWidth: '90%',
  },
  vehicleInfo: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  vehicleText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(2),
      padding: 0,
    },
  },
  price: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  strikeThrough: {
    textDecoration: 'line-through',
  },
  buttonBox: {
    width: '50%',
  },
  mobileApp: {
    '& .MuiPaper-root>.MuiDialogTitle-root': {
      display: 'none !important',
    },
    '& .MuiPaper-root>.MuiButtonBase-root': {
      display: 'none !important',
    },
    '& .MuiPaper-root>.MuiDialogActions-root': {
      paddingBottom: '32px !important',
    },
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  handleEditConfig?: () => void;
  loadingEdit?: boolean;
}

export const ConfigureVehicleModal: React.FunctionComponent<IProps> = ({
  open,
  onClose,
  handleEditConfig,
  loadingEdit,
}) => {
  const { vehicle } = useContext(VehicleContext);
  const [isFakeDoorOpen, setIsFakeDoorOpen] = useState(false);
  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  const isExpDownpayment = isBVariant || isCVariant;

  const { downPayment: isDownPayment, downPaymentAmount } = useConfigureStore();

  const classes = useStyles();
  const i18n = useIntl();
  const isMobile = useIsMobile();
  const vh = useViewportHeight();
  const {
    onContinue,
    isLoading: isLoadingFromModal,
    term,
  } = useConfigureStore();
  const isCheckoutEditModal = Boolean(handleEditConfig);

  const isForBusiness = getForBusinessCookie();

  const { total, monthlyCost } = useMonthlySubscriptionCost(vehicle);
  const preDiscountPrice = usePreDiscountRawValue(
    vehicle,
    monthlyCost,
    term,
    isForBusiness,
    isDownPayment
  );

  const originalPriceFormatted = useFormattedPrice(
    preDiscountPrice ? preDiscountPrice : 0,
    0
  );

  const isApp = isMobileApp();
  const finalPriceFormatted = useFormattedPrice(total, 0);

  const subtitle = i18n.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const isShortPreDiscountPrice = preDiscountPrice?.toString().length === 4;

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'configure',
    });
    onClose();
  }, [onClose]);

  return (
    <Modal
      disableBrowserHistory
      open={open}
      onClose={handleModalClose}
      type="alert"
      hasDesktopTransition={!isMobile}
      fullScreen={true}
      variant={ModalHeaderVariant.SMALL}
      calculatedVh={vh}
      className={[isApp && classes.mobileApp].join(' ')}
    >
      <ModalHeader variant={ModalHeaderVariant.SMALL}>
        {i18n.formatMessage({ id: 'pdp.configure.generalTitle' })}
      </ModalHeader>

      <ModalContent className={classes.content}>
        <ConfigureVehicleHeader vehicle={vehicle} />

        {/*hide downpayment for edit modal*/}
        {isCheckoutEditModal && !isDownPayment ? null : isExpDownpayment &&
          term > 1 ? (
          <DownPaymentExperiment />
        ) : (
          <DownPayment />
        )}

        <ContractDuration />

        <DistanceOptions />
      </ModalContent>

      <ModalFooter>
        <div className={classes.footer} translate="no">
          <div className={classes.priceSection}>
            <div className={classes.price}>
              {!!preDiscountPrice && (
                <span
                  className={`global-t5-semibold ${isShortPreDiscountPrice ? 'sm:mobile-t4-semibold md:web-t4-semibold' : 'sm:mobile-t3-semibold md:web-t3-semibold'} !text-iron line-through`}
                >
                  {originalPriceFormatted}
                </span>
              )}
              <span
                className={`global-t5-semibold ${isShortPreDiscountPrice ? 'sm:mobile-t4-semibold md:web-t4-semibold' : 'sm:mobile-t3-semibold md:web-t3-semibold'} !text-black`}
              >
                {finalPriceFormatted}
              </span>
            </div>
            <span className="body-14-light">{subtitle}</span>
          </div>

          <div className={classes.buttonBox}>
            <ContinueButton
              isInPDP={true}
              onClick={() => {
                if (isExpDownpayment && downPaymentAmount > 1200) {
                  interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
                    name: 'downpayment options',
                  });
                  setIsFakeDoorOpen(true);
                } else {
                  onContinue();
                }
              }}
              isLoading={isLoadingFromModal || loadingEdit}
              testId="submit-config"
            />
          </div>
        </div>

        <DownpaymentOptionsModal
          isOpen={isFakeDoorOpen}
          onClose={() => setIsFakeDoorOpen(false)}
        />
      </ModalFooter>
    </Modal>
  );
};
