import { Button } from '@finn/atoms';
import { Modal, ModalContent, ModalHeader } from '@finn/atoms/Modal';
import { parseToHtml } from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import { PDPData } from '~/types/cosmicModules';

import { useConfigureStore } from '../../ConfigurePage/ConfigurePageStore';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    margin: theme.spacing(4, 0, 0, 0),
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
}));

type Props = {
  open: boolean;
  onClose(): void;
  pageData: PDPData;
};

const DirectCheckoutConfirmation: React.FC<Props> = ({
  open,
  onClose,
  pageData,
}) => {
  const classes = useStyles();
  const { onDirectCheckoutContinue } = useConfigureStore();

  const title =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.metadata
      .modal_title;
  const acceptButtonText =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.metadata
      .accept_button_text;
  const rejectButtonText =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.metadata
      .reject_button_text;
  const description =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.content;

  const handleClose = useCallback(() => {
    const isApp = Boolean(
      typeof window !== 'undefined' ? window?.nativeAppSDK : false
    );
    // inside of the app we do full redirect, which triggers the handleClose each time together with submit
    if (!isApp) {
      onDirectCheckoutContinue({});
    }
    onClose();
  }, [onClose, onDirectCheckoutContinue]);

  return (
    <Modal open={open} onClose={handleClose} scrollAll>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        <div className="body-16-regular [&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden">
          {parseToHtml(description)}
        </div>
        <Box
          className={classes.buttonGroup}
          data-appid="direct-checkout-button-group"
        >
          <Button
            label={acceptButtonText}
            onClick={() => {
              onDirectCheckoutContinue({ accepted: true });
              onClose();
            }}
            size="large"
            fullWidth
          />
          <Button
            label={rejectButtonText}
            onClick={() => {
              onDirectCheckoutContinue({ accepted: false });
              onClose();
            }}
            variant="outlinedDark"
            size="large"
            fullWidth
          />
        </Box>
      </ModalContent>
    </Modal>
  );
};
export default DirectCheckoutConfirmation;
