import { Container } from '@finn/atoms';
import { useMonthlySubscriptionCost } from '@finn/auto-ui/modules/products/details/components/PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { getForBusinessCookie, usePreDiscountRawValue } from '@finn/ui-modules';
import { cn, useFormattedPrice } from '@finn/ui-utils';
import { useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import AvailabilityText from '~/components/ProductDetails/AvailabilityText';
import { useStyles } from '~/components/ProductDetails/StickyBar/style';
import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { GenericVehicleDetails, getEquipmentLine } from '~/contexts/Vehicle';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

export type Props = {
  className?: string;
  position?: 'top' | 'bottom';
  isAvailable: boolean;
  show: boolean;
  infoLines?: string[];
  vehicle: GenericVehicleDetails;
  renderSubmitButton(): React.ReactNode;
};

const StickyBar: React.FC<Props> = ({
  vehicle,
  className,
  isAvailable,
  renderSubmitButton,
  show,
  position = 'top',
}) => {
  const theme = useTheme();

  // Add a bottom padding to prevent StickyBar from overlapping the footer
  useEffect(() => {
    if (position === 'bottom') {
      document.body.style.paddingBottom = theme.customStyles.stickyNav.height;

      return () => {
        document.body.style.paddingBottom = '';
      };
    }
  }, [position, theme]);

  const stickyTopRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const i18n = useIntl();
  const equipmentLine = getEquipmentLine(vehicle);
  const configLineLabel = `${vehicle.engine ?? ''} ${equipmentLine}`;
  const isForBusiness = getForBusinessCookie();

  const { total } = useMonthlySubscriptionCost(vehicle);
  const finalPriceFormatted = useFormattedPrice(total);

  const preDiscountPrice = usePreDiscountRawValue(vehicle, total);

  const originalPrice = preDiscountPrice ? preDiscountPrice : null;
  const originalPriceFormatted = useFormattedPrice(originalPrice, 0);

  const subtitle = i18n.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const rootClassName = cn(classes.stickyBar, 'bg-white', {
    [classes.stickyBarBottom]: position === 'bottom',
    [classes.stickyBarVisible]: show,
    [className]: Boolean(className),
  });

  // Delay always a second to prevent issue between desktop/mobile position
  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000);
  }, []);

  if (!loaded) return;

  return (
    <div className={rootClassName} ref={stickyTopRef} data-appid="sticky-bar">
      <Container>
        <div className={classes.stickyTopContent}>
          <div className={classes.productBox}>
            <div className={classes.vehicleModelBox}>
              <span className="global-t5-semibold inline-block">
                {vehicle.brand.id} {vehicle.model}
              </span>
              <span className="global-t5-light mt-2 inline-block">
                &nbsp;{configLineLabel}
              </span>
            </div>
            <AvailabilityText showSmallerVersion />
          </div>
          <div className={classes.priceBox}>
            <div className={classes.flexEndLG}>
              <span className="global-t5-semibold inline-block">
                {i18n.formatMessage({ id: 'pdp.price.starting_from' })}&nbsp;
              </span>
              {originalPrice && (
                <span className="global-t5-semibold text-iron mr-2 inline-block line-through">
                  {originalPriceFormatted}
                </span>
              )}
              <span className="global-t5-semibold inline-block">
                {finalPriceFormatted}
              </span>
            </div>
            <p className={cn(classes.flexEndLG, 'body-14-regular mt-1.5')}>
              {subtitle}
            </p>
          </div>
          <div
            onClick={PDPTracking.stickyAddToCartClicked}
            className={classes.button}
          >
            {isAvailable && renderSubmitButton()}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default StickyBar;
