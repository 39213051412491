import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { Button } from '@finn/ui-components';
import { useRouter } from 'next/router';
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { PLPRoute } from '~/types/general';

type Props = {
  vehicle?: GenericVehicleDetails;
  onClose(): void;
};

const NotifySuccess: React.FC<Props> = ({ vehicle, onClose }) => {
  const i18n = useIntl();

  const router = useRouter();

  const handleCtaClick = useCallback(() => {
    onClose();
    router.push(PLPRoute.SUBSCRIPTION);
  }, [onClose, router]);

  const title = i18n.formatMessage({
    id: `general.notifyMeModal.comingSoon.successTitle`,
  });

  const description = i18n.formatMessage(
    { id: `general.notifyMeModal.comingSoon.successDescription` },
    {
      location: null,
      car: vehicle ? `${vehicle.brand?.id} ${vehicle.model}` : '',
    }
  );

  const ctaLabel = i18n.formatMessage({
    id: `general.notifyMeModal.comingSoon.successCtaLabel`,
  });

  return (
    <>
      <h3 className="mobile-t3-semibold md:web-t3-semibold mb-8">{title}</h3>

      <p className="body-16-light mb-8">{description}</p>

      <div>
        <Button
          label={ctaLabel}
          color="primary"
          data-cy="browse-more"
          onClick={handleCtaClick}
        />
      </div>
    </>
  );
};

export default memo(NotifySuccess);
