import { Modal, ModalContent, ModalFooter, ModalHeader } from '@finn/atoms';
import KeyData from '@finn/auto-ui/components/KeyData';
import { EquipmentDetailsComponent } from '@finn/auto-ui/components/PDP/EquipmentDetailsComponent';
import VehiclesModel from '@finn/auto-ui/components/ProductDetails/VehiclesModel';
import { useViewportHeight } from '@finn/auto-ui/hooks/useViewportHeight';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { useIsMobile } from '@finn/ui-utils';
import { Divider, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  modalHeader: {
    paddingLeft: theme.spacing(3),
  },
  modalContent: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  productDetailsData: any;
}

export const CarInformationDrawer: React.FunctionComponent<IProps> = ({
  open,
  onClose,
  productDetailsData,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const vh = useViewportHeight();

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'carinfo',
    });
    onClose();
  }, [onClose]);

  return (
    <Modal
      disableBrowserHistory
      open={open}
      onClose={handleModalClose}
      type="alert"
      hasDesktopTransition={!isMobile}
      fullScreen={isMobile}
      calculatedVh={vh}
    >
      <ModalHeader className={classes.modalHeader}>
        <VehiclesModel drawerView={true} />
      </ModalHeader>

      <ModalContent className={classes.modalContent}>
        <KeyData />
        <Divider />
        <EquipmentDetailsComponent productDetailsData={productDetailsData} />
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
};
