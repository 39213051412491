import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@finn/atoms';
import { useConfigureStore } from '@finn/auto-ui/components';
import { VehicleContext } from '@finn/ua-vehicle';
import { calculateTotalPrice } from '@finn/ui-modules';
import { getFormattedPrice } from '@finn/ui-utils';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DownpaymentOptionsModal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);

  const {
    term,
    onContinue,
    kilometerPackage,
    isLoading: isLoadingFromModal,
  } = useConfigureStore();

  const totalPrice = calculateTotalPrice({ vehicle, term, kilometerPackage });
  const price = getFormattedPrice(i18n, totalPrice);

  const downPaymentContent = i18n.formatMessage(
    { id: 'pdp.downPayment.modalContent' },
    { price, brand: vehicle.brand.id, model: vehicle.model }
  );

  const handleModalClose = () => onClose();

  return (
    <Modal size="xs" open={isOpen} onClose={handleModalClose}>
      <ModalHeader>
        {i18n.formatMessage({ id: 'pdp.downPayment.modalTitle' })}
      </ModalHeader>
      <ModalContent>
        <div className="body-14-regular">{downPaymentContent}</div>
      </ModalContent>
      <ModalFooter>
        <Button
          fullWidth
          size="large"
          onClick={onContinue}
          loading={isLoadingFromModal}
          label={i18n.formatMessage({ id: 'pdp.downPayment.modalButton' })}
        />
      </ModalFooter>
    </Modal>
  );
};
