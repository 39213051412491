import { Badge } from '@finn/atoms';
import { ProductOptionCard } from '@finn/platform-modules';
import {
  Features,
  useDownPayment,
  useIsABVariant,
} from '@finn/ua-featureflags';
import { InfoToolTip } from '@finn/ui-components';
import { calculateTotalPrice, getForBusinessCookie } from '@finn/ui-modules';
import { getFormattedPrice, trackEvent } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { removeGermanTax } from '~/utils/checkout';

import { useConfigureStore } from '../ConfigurePageStore';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  options: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  oneTimePriceRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    transition: '0.3s height, 0.3s opacity',
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    alignItems: 'center',
    '&.visible': {
      height: 48,
      opacity: 1,
    },
  },
}));

export const DownPayment: React.FC = () => {
  const i18n = useIntl();
  const classes = useStyles();
  const { isDownPaymentEnabled } = useDownPayment();
  const [isForBusiness, setIsForBusiness] = useState(false);
  const { setDownPayment, downPayment, vehicle, term } = useConfigureStore();
  const isExperimentDownPaymentDiscount = useIsABVariant(
    Features.ExpDownPaymentOptions
  );

  useEffect(() => {
    const isForBusinessCookie = getForBusinessCookie();
    setIsForBusiness(isForBusinessCookie);
  }, []);

  const handleOptionClick = (withDownpayment: boolean) => () => {
    trackEvent(`DownPayment Selected`, { downPayment: withDownpayment });
    setDownPayment(withDownpayment);
  };

  const downPaymentAmount = isForBusiness
    ? Math.round(
        removeGermanTax(vehicle?.downpayment_prices?.downpayment_fixed_amount)
      )
    : vehicle?.downpayment_prices?.downpayment_fixed_amount;

  const oneTimePrice = getFormattedPrice(i18n, downPaymentAmount);
  const price = calculateTotalPrice({ term, vehicle });
  const downpaymentPrice = calculateTotalPrice({
    term,
    vehicle,
    isDownPayment: true,
    downPaymentAmount,
    isExperimentDownPayment: isExperimentDownPaymentDiscount,
  });

  const downpaymentDiscount = price - downpaymentPrice;

  if (!vehicle) return null;

  if (!isDownPaymentEnabled) return null;

  return (
    <div className={classes.root} translate="no">
      <div className="my-2 flex flex-row items-center gap-2">
        <h6 className="global-t6-semibold">
          {i18n.formatMessage({ id: 'pdp.downPayment.title' })}
        </h6>

        <InfoToolTip
          content={i18n.formatMessage({
            id: `${isExperimentDownPaymentDiscount ? 'pdp.downPayment.tooltipExperiment' : 'pdp.downPayment.tooltip'}`,
          })}
        />
      </div>

      <div className={classes.options}>
        <ProductOptionCard
          title={i18n.formatMessage({ id: 'pdp.downPayment.optionTitleWith' })}
          descComponent={
            !isExperimentDownPaymentDiscount && (
              <Badge
                label={i18n.formatMessage({
                  id: 'pdp.downPayment.optionDescriptionWith',
                })}
                className="mb-1 mt-2"
              />
            )
          }
          isSelected={downPayment === true}
          onClick={handleOptionClick(true)}
        />
        <ProductOptionCard
          title={i18n.formatMessage({
            id: 'pdp.downPayment.optionTitleWithout',
          })}
          desc={`+ ${getFormattedPrice(i18n, downpaymentDiscount, 0)}`}
          isSelected={downPayment === false}
          onClick={handleOptionClick(false)}
        />
      </div>
      <div
        className={`${classes.oneTimePriceRoot} ${downPayment ? 'visible' : ''}`}
      >
        <div className="body-14-semibold">
          {i18n.formatMessage({ id: 'pdp.downPayment.oneTimePrice' })}
        </div>
        <div className="body-14-regular">{oneTimePrice}</div>
      </div>
    </div>
  );
};
