import { SkeletonNode } from '@finn/atoms/SkeletonNode';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { VehicleContext } from '@finn/ua-vehicle';
import {
  getForBusinessCookie,
  useCalculatePrice,
  usePreDiscount,
} from '@finn/ui-modules';
import { useFormattedPrice } from '@finn/ui-utils';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { useConfigureStore } from '../../ConfigurePage';

const SubscriptionPrice: React.FC<{ className?: string }> = ({ className }) => {
  const i18n = useIntl();
  const isForBusiness = getForBusinessCookie();
  const { vehicle } = useContext(VehicleContext);
  const { kilometerPackage, term, downPayment, downPaymentAmount } =
    useConfigureStore();
  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  const isExperimentDownPayment = isBVariant || isCVariant;

  const isDownPayment = downPayment;

  const isFakedoorDownpayment =
    useIsABVariant(Features.ExpFakedoorDownpayment) && term !== 1;
  const params = {
    term,
    vehicle,
    isDownPayment,
    kilometerPackage,
    downPaymentAmount,
    isFakedoorDownpayment,
    isExperimentDownPayment,
  };
  const totalPrice = useCalculatePrice(params);

  const monthlyPriceString = useFormattedPrice(totalPrice, 0);

  const preDiscountPrice = usePreDiscount({
    vehicle,
    price: totalPrice,
    term,
    isForBusiness,
    isDownPayment,
  });

  const title = i18n.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  return (
    <div className={className}>
      <div className="flex">
        <span className="mobile-t3-semibold sm:web-t3-semibold inline-block">
          {i18n.formatMessage({ id: 'pdp.price.starting_from' })}&nbsp;
        </span>

        {preDiscountPrice && (
          <SkeletonNode loading={false}>
            <span className="mobile-t3-semibold sm:web-t3-semibold !text-iron mr-2 inline-block line-through">
              {preDiscountPrice}
            </span>
          </SkeletonNode>
        )}

        <SkeletonNode loading={false}>
          <span className="mobile-t3-semibold sm:web-t3-semibold inline-block">
            {monthlyPriceString}
          </span>
        </SkeletonNode>
      </div>
      <div className="body-14-light pb-4 pt-2">
        {title}
        {downPayment && (
          <>
            <br />
            {i18n.formatMessage({ id: 'pdp.downPayment.priceText' })}
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPrice;
