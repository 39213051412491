import { ClockOutlined } from '@finn/design-system/icons/clock-outlined';
import React from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  productStockTotal: number;
  sm?: boolean;
}

const LeftInStock: React.FunctionComponent<IProps> = ({
  productStockTotal,
  sm,
}) => {
  const i18n = useIntl();

  if (productStockTotal > 3) {
    return null;
  }

  const label = i18n.formatMessage(
    {
      id: `general.${
        productStockTotal > 1 ? 'leftInStockPlural' : 'leftInStockSingular'
      }`,
    },
    { total: productStockTotal }
  );

  return (
    <div className="flex items-center">
      <ClockOutlined className="mr-2" />
      <p className={sm ? 'body-14-light' : 'body-14-light md:body-16-light'}>
        {label}
      </p>
    </div>
  );
};

export default LeftInStock;
