import { Container } from '@finn/atoms';
import { ConditionalWrapper } from '@finn/auto-ui/utils/general';
import { SpacingWrapper } from '@finn/platform-modules';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { Grid } from '@material-ui/core';
import React from 'react';

import Equipment from '~/components/Equipment';
import EquipmentDetails from '~/components/EquipmentDetails';

export const EquipmentDetailsComponent: React.FC<{
  productDetailsData: any;
}> = ({ productDetailsData }) => {
  const drawerView = useIsABVariant(Features.ExpPDPCarInfo);

  return (
    <Container>
      <ConditionalWrapper
        condition={!drawerView}
        wrapper={(wrapperChildren: React.FC) => (
          <SpacingWrapper>{wrapperChildren}</SpacingWrapper>
        )}
      >
        <Grid container={!drawerView}>
          <Grid item md={drawerView ? 12 : 6} xs={12}>
            <Equipment productDetailsData={productDetailsData} />
          </Grid>
          <Grid item md={drawerView ? 12 : 6} xs={12}>
            <EquipmentDetails />
          </Grid>
        </Grid>
      </ConditionalWrapper>
    </Container>
  );
};
