import { Container } from '@finn/atoms';
import CosmicModule from '@finn/auto-ui/ui-modules/CosmicModule';
import { SpacingWrapper } from '@finn/platform-modules';
import React from 'react';

import { CosmicModuleType, PDPModules } from '~/types/cosmicModules';

export const ComparisonTable: React.FC<{ pdpModules: PDPModules }> = ({
  pdpModules,
}) => {
  const comparisonTableModule = pdpModules.metadata.modules.find(
    (module) => module.type === CosmicModuleType.WEB_MODULE_COMPARISON
  );
  if (!comparisonTableModule) {
    return null;
  }

  return (
    <Container>
      <SpacingWrapper>
        <CosmicModule data={comparisonTableModule} />
      </SpacingWrapper>
    </Container>
  );
};
